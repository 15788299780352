import { inject, Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '@store/scope';
import { PermissionEnum } from '@consensus/connect/shared/access-control/domain';

@Pipe({
	standalone: true,
	name: 'hasAnyPermission',
})
export class HasAnyPermissionPipe implements PipeTransform {
	readonly #sessionService = inject(SessionService);

	transform(permissions: PermissionEnum[] | Set<PermissionEnum>): boolean {
		return this.#sessionService.hasAnyPermission(permissions);
	}
}
