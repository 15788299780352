export enum CoPosthogFeatureFlag {
	ManagerVideoUploadEnabled = 'ManagerVideoUploadEnabled',
	SplitScreenEnabled = 'SplitScreenEnabled',
	ConnectCmsRiveEnabled = 'ConnectCmsRiveEnabled',
	SharedCmsScormEnabled = 'SharedCmsScormEnabled',
	ConnectCmsVideoChatEnabled = 'ConnectCmsVideoChatEnabled',
	ConnectLivestreamClassicFeatureIntegrationEnabled = 'ConnectLivestreamClassicFeatureIntegrationEnabled',
	ConnectLivestreamClassicEnabled = 'ConnectLivestreamClassicEnabled',
	ConnectVideoChatCallSurveyEnabled = 'ConnectVideoChatCallSurveyEnabled',
	AcademyCmsSowFeaturesEnabled = 'AcademyCmsSowFeaturesEnabled',
}
