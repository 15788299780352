<div class="loading" *ngIf="loading">Loading PDF...</div>
<ng-container *ngIf="!loading && url && !suspended">
	<ngx-extended-pdf-viewer
		[useBrowserLocale]="true"
		[src]="url"
		(pdfLoaded)="loaded.emit()"
		[showPrintButton]="isAdmin || allowSharing"
		[showDownloadButton]="isAdmin || allowSharing"
		(pdfDownloaded)="capacitorDownloadHandler()"
		[showBookmarkButton]="false"
		[showUnverifiedSignatures]="true"
		(pdfLoadingFailed)="onError($event)"
		[sidebarVisible]="initialSidebar && (autohideSidebar$ | async) !== true"
		[showSidebarButton]="true"
		[showOpenFileButton]="false"
		[showTextEditor]="false"
		[showDrawEditor]="false"
		[textLayer]="true"
		zoom="auto"
		[showPresentationModeButton]="true"
	/>
</ng-container>
